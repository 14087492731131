<template>
  <div id="notificacoes">
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
      :key="componentKey"
      ref="dropMenu"
    >
      <template #button-content>
        <feather-icon
          :badge="notificacoesContador"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <!-- Header -->
      <div class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
          <b-badge pill variant="light-primary">
            <span v-if="notificacoesContador > 0"
              >{{ notificacoesContador }} nova(s)</span
            >
            <span v-else>sem notificações</span>
          </b-badge>
        </div>
      </div>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        v-if="notificacoesContador > 0"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Notificações do Usuário -->
        <b-link
          v-for="notificacao in notif"
          :key="notificacao.id_notificacao_usuario + componentKey"
          @click.prevent="atualizaNotificacaoUsuarioComoLida(notificacao)"
        >
          <b-media>
            <template #aside>
              <b-avatar :variant="getDataIcon(notificacao).variant">
                <feather-icon :icon="getDataIcon(notificacao).icon" />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span
                :class="'font-weight-bolder ' + getClasse(notificacao)"
                v-html="notificacao.titulo"
              ></span>
            </p>
            <small
              class="notification-text"
              v-html="formataNotificacao(notificacao)"
            ></small>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import { eventBus } from "@/eventbus/eventBus";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { TIPONOTIFICACAO } from "@/custom-enum/tipo-notificacao.js";
import { $tabComponent } from "@/views/pages/aplicacoes/visualizar-aplicacao/tab-components/dados-aplicacao-components/accessEnum.js";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  data() {
    return {
      usuarioLogado: this.$store.state.sessions.userData,
      notificacoes: [],
      aguardandoJobMarcarComoLida: [],
      componentKey: 0,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    getRotaApiAtualizacao(idNotificacaoUsuario) {
      return this.$helpers.preparaRotaComParametros(
        this.$api.MinhasNotificacoesAtualizar,
        "{notificacaoUsuario}",
        idNotificacaoUsuario
      );
    },

    atualizaNotificacaoUsuarioComoLida(value) {

      let { id_notificacao_usuario, id_tipo_notificacao, url, descricao } = value;
      let { role } = this.usuarioLogado;

      let hasPerfilSaude = role.perfil.some((item) => item.id_perfil === 5);

      if(TIPONOTIFICACAO.EXPORTACAO_RESPOSTAS_APLICACAO === descricao){
        if(url){
          if(this.$router.history.current.fullPath ==='/visualizar-aplicacao'){
            this.$eventHub.emit('novaMensagemResposta','Concluído');
          }else{
            this.$router.push({
              name: 'visualizar-aplicacao',
              params: {
                idAplicacao: url.split('/visualizar-aplicacao/')[1],
                tabComponent: $tabComponent.exportarRespostas,
                subAccess: null
              }
            });
          }
        }
        this.$store.dispatch(
          "notifications/updateUserNotificationAsRead",
          { idNotificacaoUsuario: id_notificacao_usuario }
        );
      }else if(TIPONOTIFICACAO.ALERTA_DE_RISCO_A_VIDA === id_tipo_notificacao){
        if(hasPerfilSaude){
          if(url){
            if(this.$router.history.current.fullPath !== "/saude-mental/respostas-compartilhadas-risco-a-vida"){
              this.$router.push(url);
            }
          }
        }else{
          this.$store.dispatch(
            "notifications/updateUserNotificationAsRead",
            { idNotificacaoUsuario: id_notificacao_usuario }
          );
        }
      }else if(TIPONOTIFICACAO.EXPORTACAO_RELATORIO_ACOMPANHAMENTO === descricao){
        if(url){

            if(this.$router.history.current.fullPath !== "/relatorio-acompanhamento"){
              this.$router.push(url);
            }
            this.$eventHub.emit('atualizarDadosRelatorio','Concluído');
            this.$store.dispatch(
              "notifications/updateUserNotificationAsRead",
              { idNotificacaoUsuario: id_notificacao_usuario }
            );
          }
      }else{
        this.$store.dispatch(
          "notifications/updateUserNotificationAsRead",
          { idNotificacaoUsuario: id_notificacao_usuario }
        );
      }

      this.$refs.dropMenu.hide();

    },

    setNotificacoes() {
      let notifications = this.$store.getters["notifications/getNotifications"];
      this.componentKey = notifications.updated_at * 9;
      this.notificacoes = notifications.itens;
      return notifications.itens;
    },
    formataNotificacao(notificacao) {
      let retorno = notificacao.conteudo.replaceAll("\n", "<br>");
      switch (notificacao.id_tipo_notificacao) {
        case TIPONOTIFICACAO.ALERTA_DE_RISCO_A_VIDA:
          retorno +=
            "<br><br><b>Clique aqui para acessar as respostas com risco à vida</b>";
          break;
        case TIPONOTIFICACAO.RESPOSTAS_COMPARTILHADAS:
          retorno +=
            "<br><br><b>Clique aqui para acessar as respostas compartilhadas</b>";
          break;
        case TIPONOTIFICACAO.TERMINO_DE_CICLO:
          retorno +=
            "<br><br><b>Confira o painel de resultados da sua equipe clicando aqui</b>";
          break;
      }
      return retorno;
    },

    getClasse(notificacao) {
      let ret = "custom";

      if(notificacao.id_tipo_notificacao === TIPONOTIFICACAO.ALERTA_DE_RISCO_A_VIDA) {
          ret = "danger";
      }

      return ret;
    },
    getDataIcon(notificacao) {
      let icon = "HomeIcon",
        variant = "primary";

      switch (notificacao.id_tipo_notificacao) {
        case TIPONOTIFICACAO.IMPORTACAO_DE_PLANILHA:
          variant = "success";
          icon = "UserIcon";
          break;
        case TIPONOTIFICACAO.RESPOSTAS_COMPARTILHADAS:
          variant = "success";
          icon = "Share2Icon";
          break;
        case TIPONOTIFICACAO.ALERTA_DE_RISCO_A_VIDA:
          variant = "danger";
          icon = "AlertCircleIcon";
          break;
        case TIPONOTIFICACAO.PROXIMIDADE_DE_CONCLUSAO_DE_CICLO:
          variant = "warning";
          icon = "CalendarIcon";
          break;
        case TIPONOTIFICACAO.TERMINO_DE_CICLO:
          variant = "danger";
          icon = "DiscIcon";
          break;
        case TIPONOTIFICACAO.CANCELAMENTO_DE_CICLO:
          variant = "danger";
          icon = "FileTextIcon";
          break;
      }

      return {
        variant,
        icon,
      };
    },
  },
  computed: {
    notif: function () {
      return this.setNotificacoes();
    },
    notificacoesContador: {
      get: function () {
        let notifications =
          this.$store.getters["notifications/getNotificationsBell"].itens;
        return notifications != null ? notifications.length : 0;
      },
      set: function (newValue) {
        return newValue;
      },
    },
  },
  watch: {
    "$store.state.notifications.updated_at": {
      immediate: true,
      handler() {
        this.setNotificacoes();
      },
    },
    "$store.state.notifications.itens": {
      immediate: true,
      handler() {
        this.setNotificacoes();
      },
    },
  },
};
</script>

<style scoped>
.danger{
    color:red
}
</style>