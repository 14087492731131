export const $tabComponent = {
  dadosAplicacao: 'TabDadosAplicacao',
  linkQRCode: 'TabLinkQRCode',
  enviarEmail: 'TabEnviarEmail',
  dashboard: 'TabDashboard',
  exportarRespostas: 'TabExportarRespostas'
}

export const $subAccess = {
  visualizar: 'Visualizar',
  editar: 'Editar',
  DadosAplicacaoPausarOuCancelar: 'DadosAplicacaoPausarOuCancelar'
}

export const $typeStatus = {
  agendado: 'Agendado',
  emAndamento: 'Em andamento',
  emPausa: 'Em pausa',
  cancelado: 'Cancelado',
  finalizado: 'Finalizado'
}

export const $typeActionStatus = {
  pausar: 'pausar',
  retomar: 'retomar',
  cancelar: 'cancelar'
}

export const $typeQuestionnaire = {
  padronizado: 'Padronizado'
}
