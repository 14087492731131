<template>
  <div>
    <b-nav-item-dropdown
      menu-class="dropdown-spacing-ajuda"
    >
      <template #button-content>
        <div
          class="d-flex align-items-center custom-container"
        >
          <b-img
            class="custom-margin"
            v-bind="mainProps"
            rounded="circle"
            alt="help-circle-icon"
            :src="require('@/assets/custom-icons/help-circle.svg')"
          />
          <div
            class="custom-font"
          >
            Ajuda
          </div>
        </div>
      </template>

      <div
        v-for="(link, index) in links"
        :key="index"
      >
        <b-dropdown-item
          v-if="link.ativo"
          :to="{ name: 'faq-ajuda-e-suporte',  params: { categoria: link.nome } }"
        >
          <div
            class="d-flex align-items-center"
          >
            <div
              v-if="link.src"
            >
              <div
                v-if="link.icon"
              >
                <feather-icon
                  :icon="link.src"
                  size="20"
                />
              </div>
              <div
                v-else
              >
                <b-img
                  v-bind="mainProps"
                  rounded="circle"
                  :alt="'help-icon'+index"
                  :src="require('@/assets/custom-icons/'+link.src)"
                />
              </div>
            </div>
            <div
              :class="link.classForName"
            >
              <span>
                {{ link.nome }}
              </span>
            </div>
          </div>
        </b-dropdown-item>
      </div>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BImg
} from "bootstrap-vue";
import { MODULOS } from "@/custom-enum/modulos-enum.js";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BImg
  },
  data() {
    return {
      links: [
        {
          nome: "Empresa",
          src: "BriefcaseIcon",
          icon: true,
          classForName: "custom-label-icon-image",
          ativo: true
        },
        {
          nome: "Pessoas",
          src: "UsersIcon",
          icon: true,
          classForName: "custom-label-icon-image",
          ativo: true
        },
        {
          nome: "Saúde Mental",
          src: "HeartIcon",
          icon: true,
          classForName: "custom-label-icon-image",
          ativo: this.$store.state.sessions.userData.role.modulos.some(
            obj => obj.id_modulo === MODULOS.SAUDE_MENTAL
          )
        },
        {
          nome: "Planeja Saúde",
          src: "BarChartIcon",
          icon: true,
          classForName: "custom-label-icon-image",
          ativo: this.$store.state.sessions.userData.role.modulos.some(
            obj => obj.id_modulo === MODULOS.PLANEJA_SAUDE
          )
        },
        {
          nome: "Vídeos de suporte",
          src: "FilmIcon",
          icon: true,
          classForName: "custom-label-icon-image",
          ativo: true
        },
        {
          nome: "Cora",
          src: "HeadphonesIcon",
          icon: true,
          classForName: "custom-label-icon-image",
          ativo: this.$store.state.sessions.userData.role.modulos.some(
            obj => obj.id_modulo === MODULOS.SAUDE_MENTAL ||
            obj.id_modulo === MODULOS.PLANEJA_SAUDE ||
            obj.id_modulo === MODULOS.CORA
          )
        },
        {
          nome: "Ver tudo",
          src: null,
          classForName: "custom-label-no-icon-image",
          ativo: true
        }
      ],
      mainProps: { width: 20, height: 20, class: '' }
    };
  }
};
</script>

<style lang="scss" scoped>
  .custom-container{
    background-color: #3B5588;
    padding: 5px 11px;
    border-radius: 25px;
  }
  .custom-margin{
    margin-right: 8px;
  }
  .custom-font{
    font-style: 'Montserrat', sans-serif;
    font-weight: 400;
    margin-top: 2px;
    font-size: 13px;
  }
  .custom-label-icon-image{
    color: #676d7d;
    text-decoration: none;
    margin-left: 10px;
    margin-top: 5px;
  }
  .custom-label-no-icon-image{
    color: #2B85D6;
  }
</style>
<style>
  .dropdown-spacing-ajuda{
    padding: 0;
  }
</style>
