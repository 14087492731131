<template>
  <div class="navbar-container d-flex align-items-center">

    <div>
      <ul class="nav navbar-nav d-xl-none">
        <li class="nav-item">
          <b-link
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <feather-icon
              icon="MenuIcon"
              size="21"
            />
          </b-link>
        </li>
      </ul>
    </div>

    <div class="logo-container">
      <img
        class="logo-image"
        src="@/assets/images/logo/cliente-sesi-logo.svg"
        alt="logo cliente sesi"
        width="240"
        height="25"
      >
    </div>

    <div class="options-container">
      <b-navbar-nav class="align-items-center">
        <Ajuda />
        <Notificacoes />
        <user-dropdown />
      </b-navbar-nav>
    </div>

  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
/*import NotificationDropdown from './components/NotificationDropdown.vue'*/
import UserDropdown from './components/UserDropdown.vue'
import Notificacoes from '@/views/components/custom/notificacao/Notificacoes.vue'
import Ajuda from '@/views/components/custom/ajuda-top-bar/Ajuda.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    UserDropdown,
    Notificacoes,
    Ajuda
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
